import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AssetsManagementPage from './pages/assets-management';
import LoginPage from './pages/login';
import AuthRoute from './AuthRoute';
import PublicationsPage from './pages/publications';
import PublicationPage from './pages/publication';

function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        component={LoginPage}
                    />
                    <AuthRoute
                        exact
                        path="/"
                        component={AssetsManagementPage}
                    />
                    <AuthRoute
                        exact
                        path="/publications"
                        component={PublicationsPage}
                    />
                    <AuthRoute
                        exact
                        path="/publication/:id"
                        component={PublicationPage}
                    />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
