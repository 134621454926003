import { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';

const AssetsSearchModal = ({ pageID = null, hideModal }) => {
    const [keyword, setKeyword] = useState(null);
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedAssets, setSelectedAssets] = useState([]);

    const search = async(e) => {
        e.preventDefault();

        if (keyword) {
            setLoading(true);
            const res = await getDataFromAPI('assets/assets/?keyword=' + keyword);
            setAssets(res.data);
            setLoading(false);
        }
    }

    const addAssetToPage = async() => {
        if (pageID) {
            setLoading(true);
            const resPage = await getDataFromAPI('assets/publication-pages/' + pageID + '/');
            const page = resPage.data;
            const selectedAssetIDs = selectedAssets.map(asset => asset.id);
            await updateDataAPI('PATCH', 'assets/publication-pages/' + pageID + '/', {
                assetIDs: page.assetIDs ? page.assetIDs.concat(selectedAssetIDs) : selectedAssetIDs,
            });
            setLoading(false);
            hideModal('refresh');
        }
    }

    return (
        <Modal size="lg"
            show={true}
            onHide={hideModal}
            className="assets-search-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Search Assets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form">
                    <Form onSubmit={search}>
                        <Form.Control
                            value={keyword}
                            onChange={e => setKeyword(e.target.value)}
                        />
                    </Form>
                    {selectedAssets.length > 0 && (
                        <Button onClick={addAssetToPage}>Add to Page</Button>
                    )}
                </div>

                <div className="selected-assets">
                    <h2>Assets to add to Page</h2>
                    <div className="assets">
                        {selectedAssets.map(asset => (
                            <img src={asset.url} key={asset.id} onClick={() => {
                                setSelectedAssets(selectedAssets.filter(a => a.id !== asset.id));
                            }} />
                        ))}
                    </div>
                </div>

                <div className="result">
                    <div>Found {assets.length} assets</div>
                    <div className="assets">
                        {assets.map(asset => (
                            <img src={asset.url} key={asset.id} onClick={() => {
                                if (selectedAssets.filter(a => a.id === asset.id).length === 0) {
                                    setSelectedAssets(selectedAssets.concat(asset));
                                }
                            }} />
                        ))}
                    </div>
                </div>
            </Modal.Body>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}
export default AssetsSearchModal;