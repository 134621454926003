import { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';
import AssetModal from '../../components/assets/asset-modal';

const AssetsManagementPage = () => {
    const [assets, setAssets] = useState([]);
    const [showAssetModal, setShowAssetModal] = useState(false);
    const [assetInModal, setAssetInModal] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAssets();
    }, []);

    const fetchAssets = async () => {
        setLoading(true);
        const res = await getDataFromAPI('assets/assets/');
        setAssets(res.data);
        setLoading(false);
    }

    const deleteAsset = async(assetID) => {
        setLoading(true);
        await updateDataAPI('DELETE', 'assets/assets/' + assetID + '/');
        setAssets(assets.filter(asset => asset.id !== assetID));
        setLoading(false);
    }

    return (
        <Container className="assets-management-page">
            <div className="page-header">
                <Button className="mb-3" size="sm" onClick={() => setShowAssetModal(true)}>Add New Asset</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Image</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {assets.map(asset => (
                        <tr key={asset.id}>
                            <td>{asset.id}</td>
                            <td className="image-cell">
                                <img src={asset.url} />
                            </td>
                            <td>
                                <div>{asset.description}</div>
                            </td>
                            <td>
                                <div className="btns">
                                    <div>
                                        <Button size="sm" onClick={() => {
                                            setShowAssetModal(true);
                                            setAssetInModal(asset);
                                        }}>Edit</Button>
                                        <Button size="sm" variant="danger" onClick={() => deleteAsset(asset.id)}>Delete</Button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showAssetModal && (
                <AssetModal
                    asset={assetInModal}
                    hideModal={action => {
                        setShowAssetModal(false);
                        setAssetInModal(null);

                        if (action === 'refresh') {
                            fetchAssets();
                        }
                    }}
                />
            )}
        </Container>
    );
}

export default AssetsManagementPage;