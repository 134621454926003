import { useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';

import './styles.scss';

const AssetModal = ({ asset, hideModal }) => {
    const [url, setUrl] = useState(asset?.url);
    const [description, setDescription] = useState(asset?.description);
    const [loading, setLoading] = useState(false);

    const fileInputRef = useRef(null);

    const uploadFile = async(e) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        const res = await updateDataAPI('POST', 'assets/upload-asset-image/', formData);

        if (res.status == 200) {
            fileInputRef.current.value = null;
            setUrl(res.data);
        } else {
            window.alert('An error occured when uploading file.');
        }

        setLoading(false);
    }

    const save = async() => {
        setLoading(true);
        await updateDataAPI('PATCH', 'assets/assets/' + asset.id + '/', {
            url, description
        });
        setLoading(false);
        hideModal('refresh');
    }

    const create = async() => {
        setLoading(true);
        await updateDataAPI('POST', 'assets/assets/', {
            url, description
        });
        setLoading(false);
        hideModal('refresh');
    }

    return (
        <Modal size="lg"
            show={true}
            onHide={hideModal}
            className="asset-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="url">
                            <Form.Label>Image Url</Form.Label>
                            <Form.Control
                                type="file"
                                ref={fileInputRef}
                                onChange={uploadFile}
                            />
                        </Form.Group>

                        {url && (
                            <img src={url} />
                        )}
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control value={description} onChange={e => setDescription(e.target.value)} as="textarea" rows={10} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {asset && (
                    <Button onClick={save}>Save</Button>
                )}
                {asset === null && url && description && (
                    <Button onClick={create}>Create</Button>
                )}
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}
export default AssetModal;