import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';

const PublicationPageModal = ({ publicationPage = null, hideModal }) => {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(publicationPage?.content);

    const save = async() => {
        setLoading(true);
        try {
            const res = await updateDataAPI('PATCH', 'assets/publication-pages/' + publicationPage.id + '/', {
                content
            });
            hideModal('save', res.data);
        } catch (err) {
            window.alert('json error');
        }
        setLoading(false);
    }

    const create = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'assets/publication-pages/', {
            content,
        });
        setLoading(false);
        hideModal('create', res.data);
    }

    return (
        <Modal size="md"
            show={true}
            onHide={hideModal}
            className="publication-page-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Page</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="content">
                            <Form.Label>Content</Form.Label>
                            <Form.Control value={content} onChange={e => setContent(e.target.value)} as="textarea" rows={20} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {publicationPage ? (
                    <Button onClick={save}>Save</Button>
                ) : (
                    <Button onClick={create}>Create</Button>
                )}
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}
export default PublicationPageModal;