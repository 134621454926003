import axios from 'axios';
import Constant from '../Constant';

export const getDataFromAPI = async (baseUrl, fullUrl = false) => {
    const url = fullUrl ? baseUrl : (Constant.API_URL + baseUrl);
    const res = await axios.get(url, {
        headers: {
            Authorization: 'Token ' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)
        }
    });
    return res;
}

export const updateDataAPI = async (method, url, data = {}) => {
    const options = {
        headers: {
            Authorization: 'Token ' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)
        }
    };
    let res;
    if (method === 'PATCH') {
        res = await axios.patch(Constant.API_URL + url, data, options);
    } else if (method === 'DELETE') {
        res = await axios.delete(Constant.API_URL + url, options);
    } else {
        res = await axios.post(Constant.API_URL + url, data, options);
    }
    return res;
}