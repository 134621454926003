import { useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';
import PublicationPageModal from '../../components/publication-page/publication-page-modal';
import AssetsSearchModal from '../../components/assets-search-modal';

const PublicationPage = () => {
    const [showImportJSONModal, setShowImportJSONModal] = useState(false);
    const [jsonContent, setJSONContent] = useState(null);
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageInModal, setPageInModal] = useState(null);
    const [showPageModal, setShowPageModal] = useState(false);
    const [showAssetsSearchModal, setShowAssetsSearchModal] = useState(false);
    const [pageInAssetsSearchModal, setPageInAssetsSearchModal] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        fetchPages();
    }, []);

    const fetchPages = async () => {
        const res = await getDataFromAPI('assets/publication-pages/?publicationID=' + id + '&additionalFields=assets');
        setPages(res.data);
    }

    const importJSONContent = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'assets/import-json-content/', {
            publicationID: id,
            jsonContent: JSON.parse(jsonContent),
        });
        setPages(res.data);
        setJSONContent(null);
        setShowImportJSONModal(false);
        setLoading(false);
    }

    const deletePage = async(pageID) => {
        setLoading(true);
        await updateDataAPI('POST', 'assets/delete-page/', {
            'pageID': pageID
        });
        fetchPages();
        setLoading(false);
    }

    const deleteAllPages = async() => {
        setLoading(true);
        for (let i = 0; i < pages.length; i++) {
            await updateDataAPI('DELETE', 'assets/publication-pages/' + pages[i].id + '/');
        }
        setPages([]);
        setLoading(false);
    }

    const autoGenAssets = async(pageID = null) => {
        setLoading(true);
        const pageIDs = [];
        if (pageID !== null) {
            pageIDs.push(pageID);
        } else {
            for (let i = 0; i < pages.length; i++) {
                pageIDs.push(pages[i].id);
            }
        }
        await updateDataAPI('POST', 'assets/auto-gen-assets/', {
            pageIDs
        });
        fetchPages();
        setLoading(false);
    }

    const addNewPage = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'assets/publication-pages/', {
            content: '',
            publication: id,
            pageIndex: pages.length > 0 ? (pages[pages.length - 1].pageIndex + 1) : 0,
        });
        setPages(pages.concat(res.data));
        setLoading(false);
    }

    const removeAssetFromPage = async(page, assetID) => {
        const assetIDs = page.assetIDs.filter(id => id !== assetID);
        await updateDataAPI('PATCH', 'assets/publication-pages/' + page.id + '/', {
            assetIDs: assetIDs,
        });
        setPages(pages.map(p => {
            if (p.id === page.id) {
                return {...p, assets: page.assets.filter(a => a.id !== assetID), assetIDs: assetIDs};
            }
            return p;
        }));
    }

    return (
        <Container fluid className="publication-page">
            <div className="page-header">
                <div></div>
                <div className="btns">
                    <div>
                        <Button onClick={() => setShowImportJSONModal(true)} size="sm">Import JSON</Button>
                        <Button onClick={() => autoGenAssets()} variant="info" size="sm">Auto Gen Assets</Button>
                    </div>
                    <div>
                        <Button onClick={addNewPage} size="sm">Add New Page</Button>
                        <Button size="sm" onClick={deleteAllPages} variant="danger">Delete All Pages</Button>
                    </div>
                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Content</th>
                        <th>Assets</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {pages.map(page => (
                        <tr key={page.id}>
                            <td>{page.pageIndex + 1}</td>
                            <td className="content-cell">{page.content}</td>
                            <td className="assets-cell">
                                {page.assets && (
                                    <div className="assets">
                                        {page.assets.map(asset => (
                                            <img src={asset.url} key={asset.id} onClick={() => removeAssetFromPage(page, asset.id)} />
                                        ))}
                                    </div>
                                )}
                            </td>
                            <td>
                                <div className="btns">
                                    <div>
                                        <Button size="sm" onClick={() => {
                                            setPageInModal(page);
                                            setShowPageModal(true);
                                        }}>Edit Content</Button>
                                        <Button variant="danger" size="sm" onClick={() => deletePage(page.id)}>Delete</Button>
                                        <Button onClick={() => autoGenAssets(page.id)} size="sm" variant="info">Auto Gen Assets</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setPageInAssetsSearchModal(page);
                                            setShowAssetsSearchModal(true);
                                        }} size="sm">Insert Icon</Button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showImportJSONModal && (
                <Modal size="lg"
                    show={true}
                    onHide={() => {
                        setJSONContent(null);
                        setShowImportJSONModal(false);
                    }}
                    className="import-json-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Import Pages</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control as="textarea" value={jsonContent} onChange={e => setJSONContent(e.target.value)} rows={20} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={importJSONContent}>Import</Button>
                    </Modal.Footer>
                </Modal>
            )}

            {showPageModal && (
                <PublicationPageModal
                    publicationPage={pageInModal}
                    hideModal={(action, data) => {
                        if (action === 'save') {
                            setPages(pages.map(page => {
                                if (page.id === pageInModal.id) {
                                    return data;
                                }

                                return page;
                            }));
                        } else if (action === 'create') {
                            setPages(pages.concat([data]));
                        }
                        setPageInModal(null);
                        setShowPageModal(false);
                    }}
                />
            )}

            {showAssetsSearchModal && (
                <AssetsSearchModal
                    pageID={pageInAssetsSearchModal?.id}
                    hideModal={action => {
                        if (action === 'refresh') {
                            fetchPages();
                        }
                        setShowAssetsSearchModal(false);
                        setPageInAssetsSearchModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default PublicationPage;