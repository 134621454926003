import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import axios from 'axios';
import Constant from './Constant';
import Header from './components/header';

const AuthRoute = ({ component: Component, ...rest }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const token = localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN);
            const email = localStorage.getItem(Constant.LOCAL_STORAGE_USER_EMAIL);
            if (token) {
                try {
                    const res = await axios.post(Constant.API_URL + 'assets/check-user-token/', { token, email });
                    localStorage.setItem(Constant.LOCAL_STORAGE_USER_NAME, res.data['name']);
                    setLoading(false);
                } catch (e) {
                    window.location.href = '/login';
                    localStorage.removeItem(Constant.LOCAL_STORAGE_TOKEN);
                    localStorage.removeItem(Constant.LOCAL_STORAGE_USER_EMAIL);
                    setLoading(false);
                }
            } else {
                window.location.href = '/login';
                setLoading(false);
            }
        };

        checkUserLoggedIn();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                <div>
                    <Header />
                    <Component {...props} />
                </div>
            }
        />
    );
};

export default AuthRoute;