import { Container, Nav, Navbar } from 'react-bootstrap';
import './styles.scss';

const Header = () => {
    return (
        <Navbar bg="light" className="nav-header">
            <Container fluid>
                <Navbar.Brand href="/">
                    Webetter
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href="/publications">
                        Publications
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default Header;