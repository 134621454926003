import { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';
import PublicationModal from '../../components/publications/publication-modal';

import './styles.scss';

const PublicationsPage = () => {
    const [publications, setPublications] = useState([]);
    const [publicationInModal, setPublicationInModal] = useState(null);
    const [showPublicationModal, setShowPublicationModal] = useState(false);

    useEffect(() => {
        fetchAllPublications();
    }, []);

    const fetchAllPublications = async() => {
        const res = await getDataFromAPI('assets/publications/');
        setPublications(res.data);
    }

    return (
        <Container className="publications-page">
            <div className="page-header">
                <Button onClick={() => setShowPublicationModal(true)}>Add New Publication</Button>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Publication</th>
                    </tr>
                </thead>
                <tbody>
                    {publications.map(publication => (
                        <tr key={publication.id}>
                            <td>{publication.id}</td>
                            <td>{publication.title}</td>
                            <td>
                                <div className="btns">
                                    <div>
                                        <Button size="sm" onClick={() => {
                                            setPublicationInModal(publication);
                                            setShowPublicationModal(true);
                                        }}>Edit Info</Button>
                                        <Button href={'/publication/' + publication.id} size="sm">View</Button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showPublicationModal && (
                <PublicationModal
                    publication={publicationInModal}
                    hideModal={(action, data) => {
                        if (action === 'save') {
                            setPublications(publications.map(publication => {
                                if (publication.id === publicationInModal.id) {
                                    return data;
                                }

                                return publication;
                            }));
                        } else if (action === 'create') {
                            setPublications(publications.concat([data]));
                        }
                        setPublicationInModal(null);
                        setShowPublicationModal(false);
                    }}
                />
            )}
        </Container>
    )
}

export default PublicationsPage;